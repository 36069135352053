import React from 'react'
import styled from '@emotion/styled'
import ImageVideo from '../ImageVideo'
import { colors, breakpoints, spacing } from '../../styles/variables'
import { H3, H5, Paragraph } from '../shared/typography'
import { Section, SectionTitle, GridContainer, GridItem } from '../shared/section'
import { ArrowLink } from '../shared/link'
import ArrowRight from '../svg/arrowRight'

const FeaturedSection = styled(Section)`
  padding: 0;
  align-items: stretch;
  justify-content: stretch;
  height: 100%;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: 0em;
  }
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: 0em;
  }
`

const FeaturedGridContainer = styled(GridContainer)`
  height: 100%;
  align-items: stretch;
  justify-content: stretch;
  grid-auto-rows: min-content;
  .gatsby-image-wrapper {
    height: 100%;
    flex: 1 1 100%;
  }
  @media screen and (max-width: ${ breakpoints.tablet + 240 }px) {
    .gatsby-image-wrapper {
      height: 70vh;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    grid-gap: 0;
    .gatsby-image-wrapper {
      height: 50vh;
    }
    > div:first-of-type {
      order: 1;
    }
  }
`

const FeaturedGridItem = styled(GridItem)`
  height: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  
  &.__f_image {
    .gatsby-image-wrapper {
      height: 100%;
      flex: 1 1 100%;
    }
  }
  @media screen and (max-width: ${ breakpoints.tablet + 240 }px) {
    height: 70vh;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    height: calc(100vh - 60px);
    min-height: 0;
    &.__f_image {
      height: calc(50vh);
    }
    &.__f_info {
      height: auto;
    }
  }
`

const FeaturedInfo = styled('div')`
  display: flex;
  flex-direction: column;
  color: black;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: ${ spacing.lg }px ${ spacing.xl * 2 }px;
  @media screen and (max-width: ${ breakpoints.desktop }px) {
    padding: ${ spacing.md }px;
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: ${ spacing.md }px;
  }
`

const FeaturedEntries = ({ data, entry }) => {
  const makeContrast = function (featuredColor) {
    let textColor = colors.neutral
    const c = featuredColor ? featuredColor.substring(1) : 'f9dbbd' // strip #
    const rgb = parseInt(c, 16) // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff // extract red
    const g = (rgb >> 8) & 0xff // extract green
    const b = (rgb >> 0) & 0xff // extract blue
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709
    if (luma > 200) {
      textColor = colors.text
      return textColor
    }
    return textColor
  }
  return (
    <FeaturedSection className="fullwidth" entry={entry} fullpage>
      <SectionTitle vision={entry && entry.intersectionRatio}>{data.sectionTitle}</SectionTitle>
      {data.entryLink
        ? (data.entryLink.map(feat => (
          <FeaturedSection
            key={feat.title + feat.id}
            fullpage
            className="fullwidth"
            bgcolor={feat.featuredColor ? feat.featuredColor : colors.neutral}>
            <FeaturedGridContainer>
              <FeaturedGridItem className="__f_info" span="5">
                <FeaturedInfo>
                  <H3>{feat.title}</H3>
                  <H5>{feat.subhead}</H5>
                  <Paragraph>{feat.projectDescription && feat.projectDescription.projectDescription}</Paragraph>
                  <ArrowLink
                    bgcolor={feat.featuredColor ? feat.featuredColor : colors.neutral}
                    to={`/case-study/${ feat.slug }`}
                  >
                    <span style={{ color: `${ makeContrast(feat.featuredColor) }` }}>See more</span>
                    <ArrowRight color={makeContrast(feat.featuredColor)} />
                  </ArrowLink>
                </FeaturedInfo>
              </FeaturedGridItem>
              <FeaturedGridItem className="__f_image" span="7">
                <ImageVideo file={feat.featuredImg} />
              </FeaturedGridItem>
            </FeaturedGridContainer>
          </FeaturedSection>
        ))
        )
        : (
          <FeaturedSection
            key={data.title + data.id}
            fullpage
            className="fullwidth"
            bgcolor={data.featuredColor ? data.featuredColor : colors.neutral}>
            <FeaturedGridContainer>
              <FeaturedGridItem className="__f_info" span="5">
                <FeaturedInfo>
                  <H3>{data.title}</H3>
                  <H5>{data.subhead}</H5>
                  <Paragraph>{data.projectDescription && data.projectDescription.projectDescription}</Paragraph>
                  <ArrowLink
                    bgcolor={data.featuredColor ? data.featuredColor : colors.neutral}
                    to={`/case-study/${ data.slug }`}
                  >
                    <span style={{ color: `${ makeContrast(data.featuredColor) }` }}>See more</span>
                    <ArrowRight color={makeContrast(data.featuredColor)} />
                  </ArrowLink>
                </FeaturedInfo>
              </FeaturedGridItem>
              <FeaturedGridItem className="__f_image" span="7">
                <ImageVideo file={data.featuredImg} />
              </FeaturedGridItem>
            </FeaturedGridContainer>
          </FeaturedSection>
        )}
    </FeaturedSection>
  )
}

export default FeaturedEntries
