import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { H5 } from './shared/typography'

const EmptyImage = styled('div')`
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
`

const VideoWrapper = styled('div')`
  @keyframes pulse {
    0% {
      background-color: rgba(0,0,0,0.03);
    }
    100% {
      background-color: rgba(0,0,0,0.1);
    }
  }
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    animation: pulse 1s infinite alternate;
    z-index: 1;
  }
`

const ImageVideo = ({ objectFit, file, loading, objectPosition, className, imgClassName }) => {
  return file === null || file.file === null
    ? (
      <EmptyImage><H5>There is no image.</H5></EmptyImage>
    )
    : (
      file.file && file.file.contentType.includes('video')
        ? (
          <VideoWrapper className={`video_wrapper ${ className }`} >
            <video
              loop={true}
              autoPlay={true}
              muted={true}
              playsInline={true}
              className={`${ imgClassName } ${ objectFit === 'contain' ? 'object-contain' : 'object-cover h-full' }`}
            >
              <source src={file.file.url} type={file.file.contentType} />
            </video>
          </VideoWrapper>
        )
        : (
          <GatsbyImage
            image={file.gatsbyImageData}
            objectFit={objectFit}
            objectPosition={objectPosition}
            loading={loading || 'lazy'}
            className={className}
            imgClassName={imgClassName}
            alt={file.description}
            layout="fullWidth"
          />
        )
    )
}

export default ImageVideo
