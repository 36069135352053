import styled from '@emotion/styled'
import { ArrowLink, Link } from '../shared/link'
import { colors, radius, spacing, fonts, breakpoints } from '../../styles/variables'

const Button = styled(Link)`
  align-items: center;
  padding: ${ spacing.sm }px;
  border-radius: ${ radius.default }px;
  font-family: ${ fonts.poppins };
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  background-color: ${ props => (props.primary ? 'transparent' : `${ colors.purple }`) };
  background-size: 250%;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  ::after {
    display: none;
  }
  :focus {
    outline: 0;
  }
  :hover {
    background-color: ${ props => (props.primary ? 'transparent' : `${ colors.eggplant }`) };
    background-position: 10%;
  }
`

const ArrowButton = styled(ArrowLink)`
  color: ${ colors.neutral };
  border: 1px solid ${ colors.neutral };
  padding: ${ spacing.md }px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  span {
    margin-right: ${ spacing.sm }px;
  }
  svg {
    position: relative;
    transform: none;
  }
  &:hover {
    span {
      color: ${ colors.neutral };
    }
     svg {
       transform: translateX(16px);
     }
  }
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    padding: ${ spacing.md }px ${ spacing.sm }px;
  }
`

export { Button, ArrowButton }
