import React from 'react'
import { H2, LargeParagraph } from '../shared/typography'
import ArrowRight from '../svg/arrowRight'
import { ArrowButton } from '../shared/buttons'
import { OuterSection, Section, GridContainer, GridItem } from '../shared/section'
import { colors, breakpoints } from '../../styles/variables'
import LeadForm from '../leadForm'
import { css } from '@emotion/react'

export const formPad = css`
  background-color: white;
  padding: 0.25rem 1rem 1rem;
  margin-left: 2rem;
  @media screen and (max-width: ${ breakpoints.tablet }px) {
    margin-left: 0rem;
  }
`

const WorkWithUsBlock = ({ data }) => {
  return (
    <OuterSection bgcolor={colors.purple}>
      <Section className="fullwidth" bgcolor={colors.purple} color={colors.neutral}>
        <GridContainer>
          <GridItem span="4">
            <h2 className="h2 mr-2">{data.sectionHeader}</h2>
          </GridItem>
          <GridItem span="8">
            {data.sectionParagraph && (
              <p className="p mb-4">{data.sectionParagraph}</p>
            )}
            {data.showForm
              ? (
                <div className="bg-white px-4 pb-4 ml-0">
                  <LeadForm/>
                </div>
              )
              : (

                <ArrowButton bgcolor={colors.purple} color={colors.neutral} to={data.sectionCtaLink}>
                  <span>{data.sectionCta}</span>
                  <ArrowRight color={colors.neutral} style={{ top: 0 }}/>
                </ArrowButton>
              )}
          </GridItem>
          <GridItem span="12">
          </GridItem>
        </GridContainer>
      </Section>
    </OuterSection>
  )
}

export default WorkWithUsBlock
